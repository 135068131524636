<template>
  <div style="width: 35%;">
    <v-card>
      <v-list shaped>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="align-center">
                <v-col class="d-inline-flex flex-grow-1">
                  <v-text-field
                    v-model="newItem"
                    id="newItem"
                    name="newItem"
                    label="example.com"
                    prefix="https://"
                    @keyup.enter="addItem"
                    autocomplete="off"
                  />
                </v-col>
                <v-col
                  v-if="showPlusBtn"
                  class="flex-grow-0"
                >
                  <v-list-item-action>
                    <template>
                      <v-btn
                        icon
                        color="blue"
                        @click="addItem"
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item-action>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="items.length > 0">
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="'item' + i"
            inactive
          >
            <v-list-item-content>
              <v-list-item-title
                style="width: 200px;"
                v-bind:title="item"
              >
                <a
                  v-bind:href="item"
                  target="_blank"
                >{{ item }}
                </a>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action style="flex-direction: row;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="blue"
                    @click="copyItem(item)"
                    @mouseout="reset(item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ copyText }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="red"
                    @click="removeItem(i)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-snackbar
      v-model="errorMessage"
      color="red accent-2"
    >
      Please make sure to enter a valid website url!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="errorMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      newItem: '',
      errorMessage: false,
      typing: false,
      copyText: '',
    }
  },
  computed: {
    showPlusBtn: function () {
      return this.newItem != null && this.newItem != ''
    },
  },
  props: {
    items: {
      default: () => [],
      required: true,
    },
  },
  methods: {
    addItem() {
      let tmpItems = this.items.slice()
      this.newItem = this.newItem.trim()

      if (this.newItem.length > 0) {
        if (this.items.indexOf(this.newItem) > -1) {
          this.$toasted.info('Website already exists.')
          return
        }
        if (this.isValidHttpUrl(this.newItem)) {
          tmpItems.push(this.newItem)
        } else {
          tmpItems.push(`https://${this.newItem}`)
        }
      }
      this.$emit('update:items', tmpItems)
      this.newItem = ''
      this.typing = false
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.$emit('update:items', this.items)
    },
    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (e) {
        console.log(e)
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    copyItem(item) {
      navigator.clipboard.writeText(item)
      this.copyText = 'Copied'
    },
    reset(item) {
      this.copyText = `Copy ${item} to clipboard`
    },
  },
}
</script>

<style>
.v-ripple__container {
  display: none !important;
}
.v-application--is-ltr .v-list.v-sheet--shaped {
  padding-right: 0;
}
.v-application--is-ltr .v-list-item__action:first-child {
  margin-right: 0;
}
</style>