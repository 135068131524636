const entityLabels = [
  {
    id: 0,
    text: 'O',
    description: 'OTHER',
    prefixKey: null,
    suffixKey: null,
    backgroundColor: '#45443E',
  },
  {
    id: 1,
    text: 'ORG',
    description: 'ORGANIZATION',
    prefixKey: null,
    suffixKey: null,
    backgroundColor: '#FEDA58',
  },
  {
    id: 2,
    text: 'DATE',
    description: 'DATE',
    prefixKey: null,
    suffixKey: null,
    backgroundColor: '#FDB23A',
  },
  {
    id: 3,
    text: 'PER',
    description: 'PERSON',
    prefixKey: null,
    suffixKey: null,
    backgroundColor: '#6a74b9',
  },
  {
    id: 4,
    text: 'LOC',
    description: 'LOCATION',
    prefixKey: null,
    suffixKey: null,
    backgroundColor: '#FC415A',
  }
]

var entityLabelToId = {}
var idToEntityDescr = {}
var entityDescrToId = {}

entityLabels.forEach(element => {
  entityLabelToId[element.text] = element.id
  idToEntityDescr[element.id] = element.description
  entityDescrToId[element.description] = element.id
});

export { entityLabels, entityLabelToId, idToEntityDescr, entityDescrToId }