<template>
  <div>
    <entity-editor
      :text="transaction_text"
      :entities.sync="ner"
      :task_status="task_status"
      task="Full Task"
    />

    <normalizer-entities
      :items="orgsAndPers"
      :modelProp.sync="entities"
      :ner="ner"
    />

    <v-autocomplete
      v-if="getAccountHolderType == 'consumer'"
      class="mx-3 my-3"
      v-model="category"
      :items="getConsumerLabels"
      filled
      label="CATEGORY:"
    ></v-autocomplete>
    <v-autocomplete
      v-else
      class="mx-3 my-3"
      v-model="category"
      :items="getBusinessLabels"
      filled
      label="CATEGORY:"
    ></v-autocomplete>
  </div>
</template>

<script>
import EntityEditor from "../EntityEditor.vue";
import NormalizerEntities from "../NormalizerEntities.vue";
import {
  convert_entities_from_backend,
  convert_entities_to_backend,
  convert_ner_to_backend,
  convert_ner_from_backend,
  format_ner_annotations,
  validate_ner_annotations,
  validate_entities,
} from "../../plugins/task_validation.js";
import { entityLabelToId } from "../../model/ner_labels_schema.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    EntityEditor,
    NormalizerEntities,
  },
  props: {
    backend_labels: Object,
    user_labels: Object,
    task_status: String,
    transaction_text: String,
  },
  data() {
    return {
      input_category: null,
      input_ner: null,
      input_entities: null,
    };
  },
  watch: {
    backend_labels: function (newVal) {
      // watch it
      if (newVal == null) {
        newVal = {
          category: null,
          normalizer: {
            ner: null,
            entities: null,
          },
        };
      }
      if (newVal.category != null) {
        this.input_category = newVal.category.label.category;
      }
      if (newVal.normalizer != null) {
        this.input_ner = convert_ner_from_backend(newVal.normalizer.ner);
        this.entities = convert_entities_from_backend(newVal.entities, this.input_ner)
      }
    },
    user_labels: function (newVal) {
      // watch it
      if (newVal == null) {
        newVal = {
          category: null,
          normalizer: {
            ner: null,
            sender: null,
            receiver: null,
            intermediary: null,
          },
        };
      }
      if (newVal.category != null) {
        this.category = newVal.category.label.category;
      }
      if (newVal.normalizer != null) {
        this.ner = convert_ner_from_backend(newVal.normalizer.ner);
        this.entities = convert_entities_from_backend(newVal.entities, this.input_ner)
      }
    },
  },
  computed: {
    ...mapGetters("labels", ["getBusinessLabels", "getConsumerLabels"]),
    ...mapGetters("task", [
      "getAccountHolderType",
      "getAmount",
      "getEntryType",
      "getCurrency",
    ]),
    category: {
      get: function () {
        if (this.input_category == null) {
          if (
            this.backend_labels == null ||
            this.backend_labels.category == null
          ) {
            return null;
          }
          return this.backend_labels.category.label.category;
        }
        return this.input_category;
      },
      set: function (category) {
        this.input_category = category;
        this.emit_labels();
      },
    },
    ner: {
      get: function () {
        if (this.input_ner == null) {
          if (
            this.backend_labels == null ||
            this.backend_labels.normalizer == null
          ) {
            return [];
          }
          return convert_ner_from_backend(this.backend_labels.normalizer.ner);
        }
        return this.input_ner;
      },
      set: function (ner) {
        this.input_ner = ner;
        if (validate_ner_annotations(this.input_ner, this.transaction_text)) {
          var new_labels = format_ner_annotations(
            this.input_ner,
            this.transaction_text
          );
          this.input_ner = new_labels;
        }
        this.emit_labels();
      },
    },
    entities: {
      get: function () {
        if (this.input_entities == null) {
          if (this.backend_labels == null) {
            return []
          }
          return convert_entities_from_backend(
            this.backend_labels.normalizer.entities,
            this.ner
          )
        }
        return this.input_entities
      },
      set: function (entities) {
        this.input_entities = entities
        this.emit_labels()
      },
    },
    orgsAndPers: function () {
      var res = [
        {
          name: "None",
          id: -1,
          startOffset: -1,
          endOffset: -1,
        },
      ];
      this.ner.forEach((element) => {
        if (
          element.label == entityLabelToId["ORG"] ||
          element.label == entityLabelToId["PER"] ||
          element.label == entityLabelToId["LOC"]
        ) {
          var newElement = element;
          newElement.name = this.transaction_text.slice(
            element.startOffset,
            element.endOffset
          );
          res.push(newElement);
        }
      });
      res.sort((a, b) => (a.startOffset > b.startOffset ? 1 : -1));
      return res;
    },
  },
  methods: {
    ...mapActions("labels", ["retrieveCategorizationLabels"]),
    current_converted_labels() {
      // return current inputs as backend format
      return {
        normalizer: {
          ner: convert_ner_to_backend(this.ner),
          entities: convert_entities_to_backend(this.entities, this.ner),
        },
        category: {
          label: {
            category_type: this.getAccountHolderType,
            category: this.category,
          },
        },
      };
    },
    emit_labels() {
      var new_user_labels = {
        labels: this.current_converted_labels(),
        valid: this.validate(),
      };
      this.$emit("update:user_labels", new_user_labels);
    },
    validate_category() {
      var included = null;
      if (this.getAccountHolderType == "consumer") {
        included = this.getConsumerLabels.includes(this.category);
      } else {
        included = this.getBusinessLabels.includes(this.category);
      }
      if (this.backend_labels == null) {
        return included;
      }
      return (
        included && this.input != this.backend_labels.category.label.category
      );
    },
    validate() {
      if (this.ner == null) {
        return false;
      }
      var res = validate_ner_annotations(this.ner, this.transaction_text);
      res = res & validate_entities(this.entities, this.ner)
      res = res & this.validate_category();
      return res;
    },
  },
  mounted() {
    if (this.getBusinessLabels == null || this.getConsumerLabels == null) {
      this.retrieveCategorizationLabels();
    }
  },
};
</script>
