<template>
  <div>
    <entity-editor
      :text="transaction_text"
      :entities.sync="annotations"
      :task_status="task_status"
      task="NER Task"
    />
  </div>
</template>

<script>
import {
  convert_ner_from_backend,
  convert_ner_to_backend,
  format_ner_annotations,
  is_ner_annotations_equals,
  validate_ner_annotations,
} from '../../plugins/task_validation.js'
import EntityEditor from '../EntityEditor.vue'

export default {
  name: 'App',
  components: {
    EntityEditor,
  },
  props: {
    backend_labels: Object,
    user_labels: Object,
    task_status: String,
    transaction_text: String,
  },
  data() {
    return {
      input: null,
    }
  },
  watch: {
    backend_labels: function (newVal) {
      // watch it
      if (newVal != null) {
        this.annotations = convert_ner_from_backend(newVal)
      }
    },
    user_labels: function (newVal) {
      // prefill :
      // only trigger when it's a backend format user labels
      if (newVal != null && 'spans' in newVal) {
        this.annotations = convert_ner_from_backend(newVal)
      }
    },
  },

  computed: {
    annotations: {
      get: function () {
        if (this.input == null) {
          return convert_ner_from_backend(this.backend_labels)
        }
        return this.input
      },
      set: function (annotations) {
        this.input = annotations
        var is_valid = this.validate()
        var new_labels = this.input
        // format NER if valid
        if (is_valid) {
          new_labels = format_ner_annotations(new_labels, this.transaction_text)
        }
        new_labels = convert_ner_to_backend(new_labels)
        var new_user_labels = {
          labels: new_labels,
          valid: is_valid,
        }
        this.$emit('update:user_labels', new_user_labels)
      },
    },
  },
  methods: {
    validate() {
      return (
        validate_ner_annotations(this.input, this.transaction_text) &&
        !is_ner_annotations_equals(
          this.input,
          convert_ner_from_backend(this.backend_labels)
        )
      )
    },
  },
}
</script>
