<template>
  <div>
    <v-card
      class="mx-3 my-3 px-2 py-3 grey--text text--darken-2"
      style="font-size: 1.3em"
    >
      {{ transaction_text }}
    </v-card>
    <v-autocomplete
      class="mx-3 my-3"
      v-model="category"
      :items="items"
      filled
      :disabled="disabled"
      label="CATEGORY:"
    ></v-autocomplete>
  </div>
</template>

<script>
// import { canonical_schemas } from "../../model/category_labels_schema.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  props: {
    backend_labels: Object,
    user_labels: Object,
    task_status: String,
    transaction_text: String,
  },
  data() {
    return {
      input: null,
    };
  },
  watch: {
    backend_labels: function (newVal) {
      if (newVal != null && "label" in newVal) {
        this.category = newVal.label.category;
      }
    },
    user_labels: function (newVal) {
      // prefill :
      // only trigger when it's a backend format user labels
      if (newVal != null && "label" in newVal) {
        this.category = newVal.label.category;
      }
    },
  },
  computed: {
    ...mapGetters("labels", ["getBusinessLabels", "getConsumerLabels"]),
    ...mapGetters("task", ["getAccountHolderType"]),
    category: {
      get: function () {
        if (
          this.input == null &&
          this.backend_labels != null &&
          this.backend_labels.label != null
        ) {
          return this.backend_labels.label.category;
        }
        return this.input;
      },
      set: function (category) {
        this.input = category;
        var is_valid = this.validate();
        var new_labels = {
          label: {
            category_type: this.getAccountHolderType,
            category: this.input,
          },
        };
        var new_user_labels = {
          labels: new_labels,
          valid: is_valid,
        };
        this.$emit("update:user_labels", new_user_labels);
      },
    },
    disabled: function () {
      if (this.task_status == "DONE") {
        return true;
      }
      return false;
    },
    items: function () {
      if (this.getAccountHolderType == "consumer") {
        return this.getConsumerLabels;
      } else {
        return this.getBusinessLabels;
      }
    },
  },
  methods: {
    ...mapActions("labels", ["retrieveCategorizationLabels"]),
    validate() {
      var included = null;
      if (this.getAccountHolderType == "consumer") {
        included = this.getConsumerLabels.includes(this.category);
      } else {
        included = this.getBusinessLabels.includes(this.category);
      }
      if (this.backend_labels == null) {
        return included;
      }
      return included && this.input != this.backend_labels.label.category;
    },
  },
  mounted() {
    if (this.getBusinessLabels == null || this.getConsumerLabels == null) {
      this.retrieveCategorizationLabels();
    }
  },
};
</script>

<style scoped>
.highlight-container.highlight-container--bottom-labels {
  align-items: flex-start;
}
.highlight-container {
  line-height: 42px !important;
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
  cursor: default;
}
.highlight-container.highlight-container--bottom-labels .highlight.bottom {
  margin-top: 6px;
}
</style>