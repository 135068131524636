var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"35%"}},[_c('v-card',[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"d-inline-flex flex-grow-1"},[_c('v-text-field',{attrs:{"id":"newItem","name":"newItem","label":"example.com","prefix":"https://","autocomplete":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem.apply(null, arguments)}},model:{value:(_vm.newItem),callback:function ($$v) {_vm.newItem=$$v},expression:"newItem"}})],1),(_vm.showPlusBtn)?_c('v-col',{staticClass:"flex-grow-0"},[_c('v-list-item-action',[[_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]],2)],1):_vm._e()],1)],1)],1)],1)],1),(_vm.items.length > 0)?_c('v-list',[_c('v-list-item-group',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:'item' + i,attrs:{"inactive":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"width":"200px"},attrs:{"title":item}},[_c('a',{attrs:{"href":item,"target":"_blank"}},[_vm._v(_vm._s(item)+" ")])])],1),_c('v-list-item-action',{staticStyle:{"flex-direction":"row"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.copyItem(item)},"mouseout":function($event){return _vm.reset(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.removeItem(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)],1)}),1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"color":"red accent-2"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.errorMessage = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.errorMessage),callback:function ($$v) {_vm.errorMessage=$$v},expression:"errorMessage"}},[_vm._v(" Please make sure to enter a valid website url! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }