<template>
  <div>
    <v-btn
      class="ma-3"
      color="primary"
      @click="addEntity"
    >
      Add Entity
    </v-btn>
    <v-container
      fluid
      class="ma-0 w-full"
    >
      <v-list>
        <v-list-item
          v-for="(entity, i) in entities"
          :key="'entity' + i + '-' + entity.id"
        >
          <v-list-item-content class="pt-1 pb-1">
            <normalizer-entity
              :items="items"
              :index="i"
              :modelProp.sync="entities"
              :ner="ner"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import NormalizerEntity from './NormalizerEntity.vue'
export default {
  name: 'App',
  components: {
    NormalizerEntity,
  },
  data() {
    return {
      input_entities: [],
    }
  },
  props: {
    ner: Array,
    items: Array,
    modelProp: Array,
  },
  watch: {},
  computed: {
    entities: {
      get: function () {
        if (this.input_entities == null || this.input_entities.length == 0) {
          return this.modelProp
        }
        return this.input_entities
      },
      set: function (val) {
        this.input_entities = val
        this.$emit('update:modelProp', val)
      },
    },
  },
  methods: {
    addEntity() {
      var newEntities = this.entities.slice()
      newEntities.push({
        role: null,
        span_indices: [],
        names: [],
        websites: [],
      })
      this.entities = newEntities
      this.$emit('update:modelProp', this.entities)
    },
  },

  filters: {},
}
</script>

<style>
</style>