<template>
  <div>
    <v-snackbar
      v-model="error"
      color="red accent-2"
    >
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="error = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-row
      class="my-3 border-bottom"
      align="center"
      justify="space-between"
    >
      <v-btn
        v-if="getTaskStatus !== 'DONE'"
        color="primary"
        @click="prefill"
        class="ml-6"
      >
        Prefill labels
      </v-btn>
      <!-- <v-btn
        v-if="getTaskStatus !== 'DONE'"
        color="primary"
        @click="addRestAsOther"
        class="ml-6"
        :disabled="user_labels && user_labels.labels && user_labels.labels.length === 0"
      >
        Add rest as OTHER
      </v-btn> -->
      <v-btn
        v-if="getTaskStatus === 'IN_REVIEW'"
        color="error"
        @click="update"
        :disabled="submitButtonEnabled == 0"
      >
        Update annotations
      </v-btn>
      <v-btn
        v-if="getTaskStatus === 'TODO'"
        color="primary"
        @click="submit"
        :disabled="submitButtonEnabled == 0"
      >
        Submit annotations
      </v-btn>

      <template v-if="isAdmin">
        <template v-if="getTaskStatus === 'IN_REVIEW'">
          <v-btn
            color="green"
            @click="accept"
          > Accept annotations </v-btn>
          <v-btn
            color="error"
            @click="dialogReject = true"
          >
            Reject annotations
          </v-btn>
        </template>
      </template>

      <v-dialog
        v-model="dialogReject"
        width="800"
      >
        <v-card>
          <v-card-title class="primary white--text">
            Reject annotation
          </v-card-title>

          <v-row
            no-gutters
            class="text-center"
          >
            <v-col>
              <v-btn
                class="ma-3"
                color="primary"
                @click="reassign"
              >
                Reassign task
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="ma-3"
                color="primary"
                @click="selfAssign"
              >
                Assign task to myself
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="ma-3"
                color="primary"
                @click="reject"
              >
                Cancel task
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-btn
        @click="doNext"
        absolute
        right
      > Next </v-btn>
      <v-btn style="visibility: hidden"> Next </v-btn>
    </v-row>

    <div
      v-if="getLoadingStatus"
      class="loading-div"
    >
      <vue-spinner />
    </div>
    <div
      v-else
      class="content-div"
    >
      <v-row class="my-3 mx-3 flex-row text-center">
        <v-col>
          <span class="d-block font-weight-medium">Created at:</span>
          <span>{{ getTaskCreatedAt }}</span>
        </v-col>
        <v-col>
          <span class="d-block font-weight-medium">Account:</span>
          <span>{{ getAccountHolderType }}</span>
        </v-col>
        <v-col>
          <span class="d-block font-weight-medium">Amount:</span>
          <span>
            <span v-if="getEntryType === 'debit'">-</span>
            {{ getAmount }} {{ getCurrency }}
          </span>
        </v-col>
        <v-col>
          <span class="d-block font-weight-medium">Type:</span>
          {{ getSchema }}
        </v-col>
        <v-col v-if="labeler">
          <span class="d-block font-weight-medium">Assignee:</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ labeler.name }}</span>
            </template>
            <div style="display: flex; flex-direction: column;">
              <span>Email: {{ labeler.email_address }}</span>
              <span>Id: {{ labeler.id }}</span>
              <strong v-if="labeler.is_admin">Admin</strong>
            </div>
          </v-tooltip>
        </v-col>
        <v-col>
          <span class="d-block font-weight-medium">Status:</span>
          <span>{{ getTaskStatus }}</span>
        </v-col>
      </v-row>

      <ner-task
        :user_labels.sync="user_labels"
        :backend_labels="backend_labels"
        :task_status="getTaskStatus"
        :transaction_text="getText"
        v-if="getSchema.startsWith('ner')"
      />

      <normalizer-task
        :user_labels.sync="user_labels"
        :backend_labels="backend_labels"
        :task_status="getTaskStatus"
        :transaction_text="getText"
        v-if="getSchema.startsWith('normalizer')"
      />

      <categorization-task
        :user_labels.sync="user_labels"
        :backend_labels="backend_labels"
        :task_status="getTaskStatus"
        :transaction_text="getText"
        v-if="getSchema.startsWith('category')"
      />

      <full-task
        :user_labels.sync="user_labels"
        :backend_labels="backend_labels"
        :task_status="getTaskStatus"
        :transaction_text="getText"
        v-if="getSchema.startsWith('full')"
      />
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CategorizationTask from '../components/tasks/CategorizationTask.vue'
import FullTask from '../components/tasks/FullTask.vue'
import NerTask from '../components/tasks/NerTask.vue'
import NormalizerTask from '../components/tasks/NormalizerTask.vue'

export default {
  name: 'App',

  components: {
    NerTask,
    NormalizerTask,
    CategorizationTask,
    FullTask,
    vueSpinner: Spinner,
  },
  computed: {
    ...mapGetters('task', [
      'getText',
      'getLabels',
      'getSchema',
      'getTransactionId',
      'getAccountHolderType',
      'getEntryType',
      'getAmount',
      'getCurrency',
      'getTaskStatus',
      'getTaskCreatedAt',
      'getAssigneeId',
      'getLoadingStatus',
      'getAssigneeId',
    ]),
    ...mapGetters('auth', ['isAdmin', 'getUserId']),
    ...mapGetters('labelers', ['getLabeler']),
    submitButtonEnabled: function () {
      if (this.user_labels == null) {
        return false
      }
      return this.user_labels.valid
    },
    backend_labels: {
      get: function () {
        return this.getLabels
      },
      set: function (val) {
        return this.setLabels(val)
      },
    },
  },
  data() {
    return {
      error: false,
      errorMessage: '',
      user_labels: {
        valid: false,
        labels: [],
      },
      dialogReject: false,
      labeler: '',
    }
  },
  watch: {
    async $route(oldVal, newVal) {
      // this will be called any time the route changes
      if (oldVal.params.id != newVal.params.id) {
        await this.retrieveTaskForLabeler(this.$route.params.id)
      }
    },
  },
  methods: {
    ...mapMutations('task', ['clearTask', 'setLabels']),
    ...mapActions('task', [
      'retrieveTaskForLabeler',
      'retrieveBusinessLabels',
      'insertLabel',
      'updateLabel',
      'acceptLabel',
      'rejectLabel',
      'createTask',
      'autoAssignNewTask',
      'next',
      'prefillTask',
    ]),
    ...mapActions('labelers', ['retrieveLabeler']),
    async submit() {
      console.log(this.user_labels.labels)
      if (this.user_labels.valid) {
        let success = await this.insertLabel(this.user_labels.labels)
        if (!this.isAdmin) {
          // auto assign tasks for current schema
          await this.autoAssignNewTask()
        }
        if (success) {
          // removes auto-accept (leaving dangling code here to re-enable if ever needed)
          // if (this.isAdmin) {
          //   await this.acceptLabel()
          // }
          await this.doNext()
        }
      } else {
        this.error = true
        this.errorMessage = 'All words must be assigned a label'
      }
    },
    async doNext() {
      this.backend_labels = {}
      await this.next()
    },
    update() {
      if (this.user_labels.valid) {
        this.updateLabel(this.user_labels.labels)
      } else {
        this.error = true
        this.errorMessage = 'All words must be assigned a label'
      }
    },
    async accept() {
      await this.acceptLabel()
      await this.doNext()
    },
    async prefill() {
      this.user_labels = { labels: null, valid: true }
      this.user_labels = await this.prefillTask()
    },
    // async addRestAsOther() {
    //   console.log(this.getText)
    //   console.log(this.user_labels.labels.spans)
    // },
    async reject() {
      await this.rejectLabel()
      this.dialogReject = false
    },
    async reassign() {
      await this.rejectLabel()
      await this.createTask({
        transaction_id: this.getTransactionId,
        schema_version: this.getSchema,
        assignee_id: this.getAssigneeId,
      })
      this.dialogReject = false
    },
    async selfAssign() {
      await this.rejectLabel()
      await this.createTask({
        transaction_id: this.getTransactionId,
        schema_version: this.getSchema,
        assignee_id: this.getUserId,
      })
      this.dialogReject = false
    },
  },
  async created() {
    await this.retrieveTaskForLabeler(this.$route.params.id)
    if (this.getAssigneeId) {
      await this.retrieveLabeler(this.getAssigneeId)
      this.labeler = this.getLabeler
    }
  },
  beforeDestroy() {
    this.clearTask()
  },
}
</script>
