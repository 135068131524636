<template>
  <div>
    <entity-editor
      :text="transaction_text"
      :entities.sync="ner"
      :task_status="task_status"
      task="Normalizer Task"
    />
    <normalizer-entities
      :items="orgsAndPers"
      :modelProp.sync="entities"
      :ner="ner"
    />
  </div>
</template>

<script>
import EntityEditor from '../EntityEditor.vue'
import NormalizerEntities from '../NormalizerEntities.vue'
import {
  convert_entities_from_backend,
  convert_entities_to_backend,
  convert_ner_to_backend,
  convert_ner_from_backend,
  format_ner_annotations,
  validate_ner_annotations,
  validate_entities,
} from '../../plugins/task_validation.js'
import { entityLabelToId } from '../../model/ner_labels_schema.js'

export default {
  name: 'App',
  components: {
    EntityEditor,
    NormalizerEntities,
  },
  props: {
    backend_labels: Object,
    user_labels: Object,
    task_status: String,
    transaction_text: String,
  },
  data() {
    return {
      input_ner: null,
      input_entities: null,
    }
  },
  watch: {
    backend_labels: function (newVal) {
      // watch it
      if (newVal == null) {
        newVal = {
          ner: null,
          entities: null,
        }
      }
      this.ner = convert_ner_from_backend(newVal.ner)
      this.entities = convert_entities_from_backend(newVal.entities, this.input_ner)
    },
    user_labels: function (newVal) {
      // prefill :
      // only trigger when it's a backend format user labels
      if (newVal != null && 'ner' in newVal) {
        this.ner = convert_ner_from_backend(newVal.ner)
        this.entities = convert_entities_from_backend(newVal.entities, this.input_ner)
      }
    },
  },
  computed: {
    ner: {
      get: function () {
        if (this.input_ner == null) {
          if (this.backend_labels == null) {
            return []
          }
          return convert_ner_from_backend(this.backend_labels.ner)
        }
        return this.input_ner
      },
      set: function (ner) {
        this.input_ner = ner
        if (validate_ner_annotations(this.input_ner, this.transaction_text)) {
          var new_labels = format_ner_annotations(
            this.input_ner,
            this.transaction_text
          )
          this.input_ner = new_labels
        }
        this.emit_labels()
      },
    },
    entities: {
      get: function () {
        if (this.input_entities == null) {
          if (this.backend_labels == null) {
            return []
          }
          return convert_entities_from_backend(
            this.backend_labels.entities,
            this.ner
          )
        }
        return this.input_entities
      },
      set: function (entities) {
        this.input_entities = entities
        this.emit_labels()
      },
    },
    orgsAndPers: function () {
      var res = [
        {
          name: 'None',
          id: -1,
          startOffset: -1,
          endOffset: -1,
        },
      ]
      this.ner.forEach((element) => {
        if (
          element.label == entityLabelToId['ORG'] ||
          element.label == entityLabelToId['PER'] ||
          element.label == entityLabelToId['LOC']
        ) {
          var newElement = element
          newElement.name = this.transaction_text.slice(
            element.startOffset,
            element.endOffset
          )
          res.push(newElement)
        }
      })
      res.sort((a, b) => (a.startOffset > b.startOffset ? 1 : -1))
      return res
    },
  },
  methods: {
    checkEntitiesToNer(ner) {
      var deleted_ids = []
      var old_ner_ids = this.ner.slice().map((x) => x.id)
      var new_ner_ids = ner.slice().map((x) => x.id)
      old_ner_ids.forEach((element) => {
        if (!new_ner_ids.includes(element)) {
          deleted_ids.push(element)
        }
      })
      const sender_clear = this.sender.span_indices.some((element) => {
        return deleted_ids.includes(element)
      })
      if (sender_clear) {
        this.sender = { span_indices: [], names: [], websites: [] }
      }
      const receiver_clear = this.receiver.span_indices.some((element) => {
        return deleted_ids.includes(element)
      })
      if (receiver_clear) {
        this.receiver = { span_indices: [], names: [], websites: [] }
      }
      const intermediary_clear = this.intermediary.span_indices.some(
        (element) => {
          return deleted_ids.includes(element)
        }
      )
      if (intermediary_clear) {
        this.intermediary = { span_indices: [], names: [], websites: [] }
      }
    },
    current_converted_labels() {
      // return current inputs as backend format
      return {
        ner: convert_ner_to_backend(this.ner),
        entities: convert_entities_to_backend(this.entities, this.ner),
      }
    },
    emit_labels() {
      var new_user_labels = {
        labels: this.current_converted_labels(),
        valid: this.validate(),
      }
      this.$emit('update:user_labels', new_user_labels)
    },
    validate() {
      if (this.ner == null) {
        return false
      }
      var res = validate_ner_annotations(this.ner, this.transaction_text)
      res = res & validate_entities(this.entities, this.ner)
      return res
    },
  },
}
</script>
