<template>
  <div style="width: 60%;">
    <v-card>
      <v-list shaped>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="align-center">
                <v-col class="d-inline-flex flex-grow-1">
                  <v-combobox
                    v-model="model"
                    :items="search_items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    color="black"
                    hide-no-data
                    hide-selected
                    no-filter
                    item-text="description"
                    item-value="name"
                    label="Add a name (Company/Person)"
                    placeholder="Start typing to search"
                    prepend-icon="mdi-database-search"
                    @keyup.enter="addItem"
                  ></v-combobox>
                </v-col>
                <v-col
                  v-if="showPlusBtn"
                  class="flex-grow-0"
                >
                  <v-list-item-action>
                    <template>
                      <v-btn
                        icon
                        color="blue"
                        @click="addItem"
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item-action>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        v-if="items.length > 0"
        shaped
      >
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="'item' + i"
            inactive
          >
            <v-list-item-content>
              <v-list-item-title
                style="width: 200px;"
                v-bind:title="item"
              >{{ item }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action style="flex-direction: row;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="blue"
                    @click="copyItem(item)"
                    @mouseout="reset(item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ copyText }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="red"
                    @click="removeItem(i)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',

  data() {
    return {
      typing: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: '',
      copyText: '',
    }
  },
  computed: {
    showPlusBtn: function () {
      return this.search != null && this.search != ''
    },
    search_items() {
      var search_items = this.entries.map((entry) => {
        var description = entry.name + ' (' + entry.website + ')'

        description =
          description.length > this.descriptionLimit
            ? description.slice(0, this.descriptionLimit) + '...'
            : description
        return Object.assign({}, entry, { description })
      })
      if (search_items.length > 0) {
        return search_items
      } else {
        return [
          {
            description: 'No match found',
            name: null,
            website: null,
            disabled: true,
          },
        ]
      }
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    website_items: {
      type: Array,
      default: () => [],
      required: true,
    },
    ner_out: {
      type: String,
      default: () => '',
      required: true,
    },
  },
  watch: {
    search(newVal, oldVal) {
      if (
        this.model != null &&
        this.model.name == null &&
        typeof this.model === 'object'
      ) {
        this.model.name = oldVal
      }
      if (
        this.model != null &&
        this.model.name != null &&
        typeof this.model === 'object'
      ) {
        // company selected from search, apply website and clear this.model
        if (this.typing === true) {
          var tmpItems = this.items.slice(0, this.items.length - 1)
        } else {
          tmpItems = this.items.slice()
        }
        tmpItems.push(this.model.name)
        this.$emit('update:items', tmpItems)
        if (this.model.website != null) {
          var tmpWebsiteItems = this.website_items.slice()
          var website = this.model.website
          if (
            !(website.startsWith('http://') || website.startsWith('https://'))
          ) {
            website = 'http://' + website
          }
          tmpWebsiteItems.push(website)
          this.$emit('update:website_items', tmpWebsiteItems)
        }
        this.model = null
        this.typing = false
      }

      // Items have already been loaded
      // if (this.search_items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true
      if (newVal == null || newVal == '') {
        newVal = this.ner_out
      }
      axios
        .post(
          window.VUE_APP_COMPANY_SEARCH_URL +
            '/search/?queries=' +
            newVal +
            '&locations=&elastic_search_limit=1000&limit=10'
        )
        .then((res) => {
          this.entries = res.data[0] // TODO: remove 1 array level
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  methods: {
    addItem() {
      let tmpItems = this.items.slice()
      let newItem = this.search.trim()
      if (this.search != null && this.search.trim().length > 0) {
        if (this.items.indexOf(newItem) > -1) {
          this.$toasted.info('Item already exists.')
          return
        }
        tmpItems.push(newItem)
      }
      this.$emit('update:items', tmpItems)
      this.search = ''
      this.typing = false
    },
    removeItem(index) {
      var tmpItems = this.items.slice()
      tmpItems.splice(index, 1)
      this.$emit('update:items', tmpItems)
    },
    copyItem(item) {
      navigator.clipboard.writeText(item)
      this.copyText = 'Copied'
    },
    reset(item) {
      this.copyText = `Copy ${item} to clipboard`
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
}
</script>

<style>
</style>