<template>
  <v-card class="pa-1 d-inline-flex align-center">
    <v-select
      v-model="role"
      :items="roles"
      label="Role"
      solo
      hide-details
    />
    <v-container class="ml-0 pr-0">
      <div class="d-inline-flex align-center">
        <v-select
          class="select-entity"
          v-model="span_indices"
          :items="items"
          item-text="name"
          item-value="id"
          label="Entities"
          solo
          multiple
          chips
          hide-details
          return-object
          :disabled="!role"
        />
        <div
          v-if="details"
          class="justify-end ml-2"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                color="black"
                @click="searchGoogle(span_indices)"
              >
                <v-icon class="pt-1">
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </template>
            <span>Search on Google</span>
          </v-tooltip>

          <v-dialog
            v-model="isCompanySearchDialogOpen"
            scrollable
            max-width="1200px"
          >
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    class="mx-2"
                    icon
                    v-on="{ ...tooltip, ...dialog }"
                    color="black"
                    @click="getCompanyDetails(span_indices.map((x) => x.name).join(''))"
                  >
                    <v-icon class="pt-1">
                      mdi-text-box-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Search in company directory</span>
              </v-tooltip>
            </template>
            <v-card max-height="900px">
              <v-card-title class="text-h5">
                Search in company directory
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="searchQuery"
                  label="Search by name"
                  solo
                  class="mt-2"
                  @keyup.enter="getCompanyDetails(searchQuery)"
                  hint="Press enter to search"
                />
                <span v-if="searchQuery.length === 0">
                  The following results are already prefilled by the item <strong>{{ span_indices.map((x) => x.name).join('') }}</strong>, however you can also directly search through a list of companies by using the search filter above.
                </span>
                <v-data-table
                  class="mt-2"
                  :headers="headers"
                  :items="matchingCompanies"
                  :loading="isFetchingCompanies"
                  v-model="selectedCompanies"
                  disable-sort
                  show-select
                >
                  <template v-slot:item.tags="{ item }">
                    <v-chip
                      class="ma-2"
                      v-for="(tag, i) in item.tags"
                      :key="tag+ i"
                      :value="tag"
                      color="tertiary"
                    >
                      {{ tag }}
                    </v-chip>
                  </template>
                  <template v-slot:item.websites="{ item }">
                    <v-chip
                      class="ma-2"
                      v-for="(website, i) in item.websites"
                      :key="website + i"
                      :value="website"
                      color="tertiary"
                    >
                      <a
                        v-if="website.startsWith('http')"
                        :href="website"
                        target="_blank"
                      >{{ website }}</a>
                      <a
                        v-else
                        :href="'https://'+ website"
                        target="_blank"
                      >
                        {{ website }}
                      </a>
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="isCompanySearchDialogOpen = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="setCompanyDetailsBySelection"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </div>
      </div>
      <div class="d-flex align-center flex-wrap">
        <div
          v-if="details"
          class="d-inline-flex grow align-center"
        >
          <company-input-list
            class="mt-4"
            :items.sync="names"
            :website_items.sync="websites"
            :ner_out="span_indices.map((x) => x.name).join(' ')"
          />
          <company-website-list
            class="ml-6 mt-4"
            :items.sync="websites"
          />
        </div>
      </div>
    </v-container>
    <!-- tooltip -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-3"
          color="error"
          v-bind="attrs"
          v-on="on"
          @click="deleteEntity"
          icon
        >
          <v-icon
            color="red"
            class="pt-1"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Remove Entity</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompanyInputList from '../components/CompanyInputList.vue'
import CompanyWebsiteList from '../components/CompanyWebsiteList.vue'
export default {
  name: 'App',
  components: {
    CompanyInputList,
    CompanyWebsiteList,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          sortable: false,
        },
        { text: 'Website(s)', value: 'websites' },
        { text: 'Tags', value: 'tags' },
      ],
      roles: ['SENDER', 'RECEIVER', 'INTERMEDIARY', 'OTHER', 'UNKNOWN'],
      input_entities: null,
      input_role: null,
      input_span_indices: null,
      input_names: null,
      input_websites: null,
      input_entity_ids: null,
      isCompanySearchDialogOpen: false,
      isFetchingCompanies: false,
      searchQuery: '',
      matchingCompanies: [],
      selectedCompanies: [],
    }
  },
  props: {
    ner: Array,
    items: Array,
    index: Number,
    modelProp: Array,
  },
  watch: {
    modelProp: function (newVal) {
      // watch it
      this.input_entities = newVal
      this.input_role = newVal[this.index].role
      this.input_span_indices = newVal[this.index].span_indices
      this.input_names = newVal[this.index].names
      this.input_websites = newVal[this.index].websites
      this.input_entity_ids = newVal[this.index].entity_ids
    },
    span_indices: function (newVal, oldVal) {
      let last_choice = newVal
        .map((x) => x.id)
        .filter((n) => !oldVal.map((x) => x.id).includes(n))[0]
      if (last_choice === -1) {
        this.span_indices = [{ name: 'None', id: -1 }]
      } else if (last_choice != null) {
        this.span_indices = this.span_indices.filter((item) => item.id !== -1)
      }
    },
  },
  computed: {
    ...mapGetters('tasks', ['getMatchingCompanies']),
    details: function () {
      return (
        this.span_indices.length > 0 &&
        !this.span_indices.map((x) => x.id).includes(-1)
      )
    },
    entities: {
      get: function () {
        if (this.input_entities == null) {
          return this.modelProp
        }
        return this.input_entities
      },
      set: function (val) {
        this.input_entities = val
        this.$emit('update:modelProp', val)
      },
    },
    role: {
      get: function () {
        if (this.input_role == null) {
          return this.modelProp[this.index].role
        }
        return this.input_role
      },
      set: function (val) {
        this.input_role = val
        this.emit_update()
      },
    },
    span_indices: {
      get: function () {
        if (this.input_span_indices == null) {
          return this.modelProp[this.index].span_indices
        }
        return this.input_span_indices
      },
      set: function (val) {
        this.input_span_indices = val
        this.emit_update()
      },
    },
    names: {
      get: function () {
        if (this.input_names == null) {
          return this.modelProp[this.index].names
        }
        return this.input_names
      },
      set: function (val) {
        this.input_names = val
        this.emit_update()
      },
    },
    websites: {
      get: function () {
        if (this.input_websites == null) {
          return this.modelProp[this.index].websites
        }
        return this.input_websites
      },
      set: function (val) {
        this.input_websites = val
        this.emit_update()
      },
    },
    entity_ids: {
      get: function () {
        if (this.input_entity_ids == null) {
          return this.modelProp[this.index].entity_ids
        }
        return this.input_entity_ids
      },
      set: function (val) {
        this.input_entity_ids = val
        this.emit_update()
      },
    },
  },
  methods: {
    ...mapActions('tasks', ['fetchMatchingCompanies']),
    emit_update() {
      this.modelProp[this.index] = {
        role: this.role,
        span_indices: this.span_indices,
        names: this.names,
        websites: this.websites,
        entity_ids: this.entity_ids,
      }
      this.$emit('update:modelProp', this.modelProp)
    },
    deleteEntity() {
      this.entities.splice(this.entities.indexOf(this.entities[this.index]), 1)
    },
    searchGoogle(ids) {
      let search_string = 'https://www.google.com/search?q='
      this.ner.forEach((element) => {
        let matchedId = ids.find((x) => x.id === element.id)
        if (matchedId) {
          search_string = search_string + element.name
        }
      })
      // negate functionality if there is nothing to search
      if (search_string !== 'https://www.google.com/search?q=') {
        window.open(search_string, '_blank')
      } else {
        this.$toasted.info('Cannot search empty label')
      }
    },
    setCompanyDetailsBySelection() {
      this.isCompanySearchDialogOpen = false
      if (this.selectedCompanies) {
        if (!this.input_names) {
          this.input_names = []
        }

        if (!this.input_websites) {
          this.input_websites = []
        }

        if (!this.input_entity_ids) {
          this.input_entity_ids = []
        }

        this.selectedCompanies.forEach((element) => {
          if (element.name) {
            if (!this.input_names.includes(element.name)) {
              this.input_names.push(element.name)
            }
          }
          if (element.websites) {
            element.websites.forEach((website) => {
              if (
                website.includes('http') &&
                !this.input_websites.includes(website)
              ) {
                this.input_websites.push(website)
              }
              if (
                !website.includes('http') &&
                !this.input_websites.includes('http://' + website)
              ) {
                this.input_websites.push('http://' + website)
              }
            })
          }
          if (!this.entity_ids.includes(element.id)) {
            this.entity_ids.push(element.id)
          }
        })

        this.entities[this.index] = {
          role: this.role,
          span_indices: this.span_indices,
          names: this.names,
          websites: this.websites,
          entity_ids: this.entity_ids,
        }

        this.emit_update()
      }
    },
    async getCompanyDetails(name) {
      if (this.searchQuery || name) {
        await this.fetchMatchingCompanies(name)
      }
      if (this.searchQuery.length === 0) {
        await this.fetchMatchingCompanies(
          this.span_indices.map((x) => x.name).join('')
        )
      }
      let companies = this.getMatchingCompanies
      this.matchingCompanies = companies
      this.isFetchingCompanies = false
    },
  },

  filters: {},
}
</script>

<style lang="scss">
.font-size-small {
  font-size: 0.8rem;
}
</style>
