import { entityDescrToId, entityLabelToId, idToEntityDescr } from '../model/ner_labels_schema.js'

function validate_normalizer_annotations(entity) {
    if (entity == null) {
        return false
    }
    if (entity.span_indices == null || entity.span_indices.length == 0) {
        return false
    }
    if (entity.span_indices.map(x => x.id).includes(-1)) {
        return false
    }
    return entity.names.length > 0
}

function validate_entities(entities, ner) {
    let valid_entities = true
    entities.forEach(entity => {
        valid_entities = valid_entities && validate_normalizer_annotations(entity)
    });
    ner.forEach(annotation => {
        if (
            annotation.label == entityLabelToId["ORG"] ||
            annotation.label == entityLabelToId["PER"] ||
            annotation.label == entityLabelToId["LOC"]
        ) {
            valid_entities = valid_entities && entities.length > 0
        }
    })
    return valid_entities
}

function is_ner_annotations_equals(annotations_1, annotations_2) {
    if (annotations_1.length !== annotations_2.length) {
        return false
    }
    for (let index = 0; index < annotations_1.length; index++) {
        const e1 = annotations_1[index];
        const e2 = annotations_2[index]
        if (e1.label !== e2.label) {
            return false
        }
        if (e1.endOffset !== e2.endOffset) {
            return false
        }
    }
    return true
}

function validate_ner_annotations(annotations, text) {
    if (annotations == null) {
        return false
    }
    var nb_letters_selected = 0
    annotations.forEach(element => {
        var selection = text.substring(element.startOffset, element.endOffset)
        nb_letters_selected += selection.length - (selection.split(" ").length - 1)
    });
    var nb_letters_text = text.length - (text.split(" ").length - 1)
    return nb_letters_text === nb_letters_selected;
}

function _recursive_merge_others(annotations) {
    for (let index = 0; index < annotations.length - 1; index++) {
        const element = annotations[index];
        const next_element = annotations[index + 1]
        if ((element.label === entityLabelToId["O"]) && (next_element.label === entityLabelToId["O"])) {
            var merged_annotation = {
                "id": element.id,
                "label": element.label,
                "startOffset": element.startOffset,
                "endOffset": next_element.endOffset
            }
            var merged_annotations = annotations.slice(0, index)
            merged_annotations.push(merged_annotation)
            merged_annotations = merged_annotations.concat(annotations.slice(index + 2))
            return _recursive_merge_others(merged_annotations)
        }
    }
    return annotations
}

function onlySpaces(str) {
    return str.trim().length === 0;
}

function strip_ner_annotations(annotations, text) {
    var cleaned_annotations = []
    annotations.forEach(element => {
        var selection = text.substring(element.startOffset, element.endOffset)
        if (!onlySpaces(selection)) {
            var startDelta = 0
            for (startDelta = 0; startDelta < selection.length; startDelta++) {
                const c = selection[startDelta];
                if (c !== " ") {
                    break
                }
            }
            var endDelta = 0
            for (endDelta = 0; endDelta < selection.length; endDelta++) {
                const c = selection[selection.length - endDelta - 1];
                if (c !== " ") {
                    break
                }
            }
            cleaned_annotations.push({
                "id": element.id,
                "label": element.label,
                "startOffset": element.startOffset + startDelta,
                "endOffset": element.endOffset - endDelta
            })
        } else {
            cleaned_annotations.push(element)
        }
    });
    return cleaned_annotations
}

function format_ner_annotations(annotations, text) {
    // 1st strip entities
    var cleaned_annotations = strip_ner_annotations(annotations, text)
    // 2nd sort
    cleaned_annotations.sort((a, b) => (a.startOffset > b.startOffset) ? 1 : -1)
    // 3rd fill spaces with "O"
    var filling_others = []
    for (let index = 0; index < cleaned_annotations.length - 1; index++) {
        const element = cleaned_annotations[index];
        const next_element = cleaned_annotations[index + 1]
        if (element.endOffset < next_element.startOffset) {
            filling_others.push({
                "id": element.endOffset,
                "label": entityLabelToId["O"],
                "startOffset": element.endOffset,
                "endOffset": next_element.startOffset
            })
        }
    }
    // check end if we should add 1 entity other at the end
    if (cleaned_annotations[cleaned_annotations.length - 1].endOffset < text.length) {
        filling_others.push({
            "id": text.length,
            "label": entityLabelToId["O"], // label_id 4 is OTHER (should move definition somewhere else)
            "startOffset": cleaned_annotations[cleaned_annotations.length - 1].endOffset,
            "endOffset": text.length
        })
    }
    cleaned_annotations = cleaned_annotations.concat(filling_others)
    cleaned_annotations.sort((a, b) => (a.startOffset > b.startOffset) ? 1 : -1)
    // 4th merge recursive
    var merged = _recursive_merge_others(cleaned_annotations);
    merged.sort((a, b) => (a.startOffset > b.startOffset) ? 1 : -1)
    return merged
}

function convert_ner_to_backend(ner_annotations) {
    var spans = ner_annotations.map(item => {
        const formated_item = [];
        formated_item.push(item.endOffset, idToEntityDescr[item.label])
        return formated_item;
    })
    return { spans: spans }
}

function convert_ner_from_backend(ner_annotations) {
    if (ner_annotations == null || ner_annotations.spans == null) {
        return []
    }
    var last_offset = 0
    var spans = ner_annotations.spans.map(item => {
        const formated_item = {
            "id": last_offset,
            "label": entityDescrToId[item[1]],
            "startOffset": last_offset,
            "endOffset": item[0]
        }
        last_offset = item[0]
        return formated_item;
    })
    return spans
}

function convert_entity_to_backend(entity, ner_annotations) {
    var new_entity = { role: null, span_indices: [], names: [], websites: [] }
    if (ner_annotations == null) {
        return new_entity
    }
    if (entity.span_indices.length == 0 || entity.span_indices.map(x => x.id).includes(-1)) {
        return null
    }
    var indices = []
    for (let index = 0; index < ner_annotations.length; index++) {
        const ner_annotation = ner_annotations[index];
        if (entity.span_indices.map(x => x.id).includes(ner_annotation.id)) {
            indices.push(index)
        }
    }
    new_entity.role = entity.role
    new_entity.span_indices = indices
    new_entity.names = entity.names.slice()
    new_entity.websites = entity.websites.slice()
    if (entity.entity_ids && entity.entity_ids.length > 0) {
        new_entity.entity_ids = entity.entity_ids
    }

    return new_entity
}

function convert_entities_to_backend(entities, ner_annotations) {
    if (entities == null) {
        entities = [null]
    }
    var new_entities = []
    entities.forEach(entity => {
        new_entities.push(convert_entity_to_backend(entity, ner_annotations))
    });
    return new_entities
}

function convert_entity_from_backend(entity, ner_annotations) {
    var new_entity = {
        role: null,
        span_indices: [],
        names: [],
        websites: []
    }
    if (ner_annotations == null || ner_annotations.length == 0) {
        return new_entity
    }
    if (entity == null || entity.span_indices == null || entity.span_indices.length == 0) {
        new_entity.span_indices = [{ name: "None", id: -1 }]
        return new_entity
    }
    new_entity.role = entity.role
    new_entity.names = entity.names
    new_entity.websites = entity.websites
    new_entity.span_indices = entity.span_indices.map(idx => { return { id: ner_annotations[idx].id, startOffset: ner_annotations[idx].startOffset, endOffset: ner_annotations[idx].endOffset } })
    return new_entity
}

function convert_entities_from_backend(entities, ner_annotations) {
    var new_entities = []
    entities.forEach(entity => {
        new_entities.push(convert_entity_from_backend(entity, ner_annotations))
    });
    return new_entities
}

export {
    validate_ner_annotations,
    is_ner_annotations_equals,
    validate_entities,
    validate_normalizer_annotations,
    strip_ner_annotations,
    format_ner_annotations,
    convert_ner_to_backend,
    convert_ner_from_backend,
    convert_entities_to_backend,
    convert_entities_from_backend
}
