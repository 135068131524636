var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-1 d-inline-flex align-center"},[_c('v-select',{attrs:{"items":_vm.roles,"label":"Role","solo":"","hide-details":""},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('v-container',{staticClass:"ml-0 pr-0"},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-select',{staticClass:"select-entity",attrs:{"items":_vm.items,"item-text":"name","item-value":"id","label":"Entities","solo":"","multiple":"","chips":"","hide-details":"","return-object":"","disabled":!_vm.role},model:{value:(_vm.span_indices),callback:function ($$v) {_vm.span_indices=$$v},expression:"span_indices"}}),(_vm.details)?_c('div',{staticClass:"justify-end ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.searchGoogle(_vm.span_indices)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pt-1"},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,false,268902970)},[_c('span',[_vm._v("Search on Google")])]),_c('v-dialog',{attrs:{"scrollable":"","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.getCompanyDetails(_vm.span_indices.map(function (x) { return x.name; }).join(''))}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{staticClass:"pt-1"},[_vm._v(" mdi-text-box-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Search in company directory")])])]}}],null,false,1789701059),model:{value:(_vm.isCompanySearchDialogOpen),callback:function ($$v) {_vm.isCompanySearchDialogOpen=$$v},expression:"isCompanySearchDialogOpen"}},[_c('v-card',{attrs:{"max-height":"900px"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Search in company directory ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Search by name","solo":"","hint":"Press enter to search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCompanyDetails(_vm.searchQuery)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.searchQuery.length === 0)?_c('span',[_vm._v(" The following results are already prefilled by the item "),_c('strong',[_vm._v(_vm._s(_vm.span_indices.map(function (x) { return x.name; }).join('')))]),_vm._v(", however you can also directly search through a list of companies by using the search filter above. ")]):_vm._e(),_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.matchingCompanies,"loading":_vm.isFetchingCompanies,"disable-sort":"","show-select":""},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag,i){return _c('v-chip',{key:tag+ i,staticClass:"ma-2",attrs:{"value":tag,"color":"tertiary"}},[_vm._v(" "+_vm._s(tag)+" ")])})}},{key:"item.websites",fn:function(ref){
var item = ref.item;
return _vm._l((item.websites),function(website,i){return _c('v-chip',{key:website + i,staticClass:"ma-2",attrs:{"value":website,"color":"tertiary"}},[(website.startsWith('http'))?_c('a',{attrs:{"href":website,"target":"_blank"}},[_vm._v(_vm._s(website))]):_c('a',{attrs:{"href":'https://'+ website,"target":"_blank"}},[_vm._v(" "+_vm._s(website)+" ")])])})}}],null,false,3380085460),model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.isCompanySearchDialogOpen = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.setCompanyDetailsBySelection}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.details)?_c('div',{staticClass:"d-inline-flex grow align-center"},[_c('company-input-list',{staticClass:"mt-4",attrs:{"items":_vm.names,"website_items":_vm.websites,"ner_out":_vm.span_indices.map(function (x) { return x.name; }).join(' ')},on:{"update:items":function($event){_vm.names=$event},"update:website_items":function($event){_vm.websites=$event}}}),_c('company-website-list',{staticClass:"ml-6 mt-4",attrs:{"items":_vm.websites},on:{"update:items":function($event){_vm.websites=$event}}})],1):_vm._e()])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"color":"error","icon":""},on:{"click":_vm.deleteEntity}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pt-1",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Remove Entity")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }