<template>
  <div>
    <v-card class="mx-3">
      <entity-item-box
        class="pa-3"
        :labels="entityLabels"
        :text="text"
        :entities="entities"
        :delete-annotation="deleteEntity"
        :update-entity="updateEntity"
        :add-entity="addEntity"
      />
      <v-fab-transition>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              color="pink"
              fab
              dark
              small
              absolute
              bottom
              right
              @click="fillWithOthers"
            >
              <v-icon>mdi-flash</v-icon>
            </v-btn>
          </template>
          <span>Fill with "others"</span>
        </v-tooltip>

      </v-fab-transition>
    </v-card>
  </div>
</template>

<script>
import { strip_ner_annotations } from '../plugins/task_validation.js'

import { entityLabels, entityLabelToId } from '../model/ner_labels_schema.js'
import EntityItemBox from './EntityItemBox.vue'
export default {
  components: {
    EntityItemBox,
  },
  data() {
    return {
      entityLabels: entityLabels,
    }
  },
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
    entities: {
      type: Array,
      default: () => [],
      required: true,
    },
    task_status: {
      type: String,
      default: '',
      required: true,
    },
    task: {
      type: String,
      default: '',
      required: true,
    },
  },

  methods: {
    deleteEntity(annotationId) {
      var entities = this.entities.filter((item) => item.id !== annotationId)
      this.$emit('update:entities', entities)
    },
    updateEntity(labelId, annotationId) {
      const index = this.entities.findIndex((item) => item.id === annotationId)
      this.entities[index].label = labelId
      this.$emit('update:entities', this.entities)
    },
    fillWithOthers() {
      let len_text = this.text.length
      let sorted_entities = this.entities.sort((a, b) => {
        return a.startOffset - b.startOffset
      })

      // before 1st element
      let first_entity_start = len_text
      if (sorted_entities.length > 0) {
        first_entity_start = sorted_entities[0].startOffset
      }
      if (first_entity_start != 0) {
        sorted_entities.push({
          id: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
          startOffset: 0,
          endOffset: first_entity_start,
          label: entityLabelToId['O'],
        })
      }
      sorted_entities = sorted_entities.sort((a, b) => {
        return a.startOffset - b.startOffset
      })

      // between elements
      let last_entity_end = 0
      for (let i = 0; i < sorted_entities.length; i++) {
        let entity = sorted_entities[i]
        if (entity.startOffset > last_entity_end) {
          sorted_entities.splice(i, 0, {
            id: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
            startOffset: last_entity_end,
            endOffset: entity.startOffset,
            label: entityLabelToId['O'],
          })
          i++
        }
        last_entity_end = entity.endOffset
      }

      sorted_entities = sorted_entities.sort((a, b) => {
        return a.startOffset - b.startOffset
      })

      // after last element
      if (sorted_entities.length > 0) {
        last_entity_end = sorted_entities[sorted_entities.length - 1].endOffset
      }
      if (last_entity_end != len_text) {
        sorted_entities.push({
          id: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
          startOffset: last_entity_end,
          endOffset: len_text,
          label: entityLabelToId['O'],
        })
      }

      this.$emit('update:entities', sorted_entities)
    },
    addEntity(startOffset, endOffset, labelId) {
      const payload = {
        id: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
        startOffset,
        endOffset,
        label: labelId,
      }
      this.entities.push(payload)
      this.$emit(
        'update:entities',
        strip_ner_annotations(this.entities, this.text)
      )
    },
  },
}
</script>
